import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "core-value-conten"
}
const _hoisted_2 = { class: "" }
const _hoisted_3 = { class: "border-box" }
const _hoisted_4 = { class: "guidance-card" }
const _hoisted_5 = { class: "card-title" }
const _hoisted_6 = { class: "card-des" }
const _hoisted_7 = { class: "guidance-continaer" }
const _hoisted_8 = { class: "fs-18 mt-16" }
const _hoisted_9 = {
  key: 1,
  class: "core-value-conten"
}
const _hoisted_10 = { class: "section-note" }
const _hoisted_11 = { class: "box-title" }
const _hoisted_12 = { class: "box-desc" }
const _hoisted_13 = { class: "" }
const _hoisted_14 = { class: "border-box" }
const _hoisted_15 = { class: "fs-14 fw-700 opa-05 mb-8" }
const _hoisted_16 = { class: "card-title" }
const _hoisted_17 = { class: "card-des" }
const _hoisted_18 = { class: "guidance-continaer" }
const _hoisted_19 = { class: "index" }
const _hoisted_20 = { class: "label-list" }
const _hoisted_21 = { class: "fs-18 mt-16 section-note" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_masonry_wall = _resolveComponent("masonry-wall")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (!_ctx.skillsLoaded || _ctx.locked)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_masonry_wall, {
                items: ['1', '2'],
                "ssr-columns": 2,
                "column-width": 420,
                gap: 16
              }, {
                default: _withCtx(({ item, index }) => [
                  _createElementVNode("div", {
                    class: _normalizeClass(["guidance-box", item + index])
                  }, [
                    _createElementVNode("div", _hoisted_4, [
                      _createElementVNode("div", _hoisted_5, [
                        _createElementVNode("div", {
                          class: _normalizeClass([`${_ctx.locked ? 'image-grey' : 'image-skeleton'}`, "opa-03 br-4"]),
                          style: {"width":"30%"}
                        }, "   ", 2)
                      ]),
                      _createElementVNode("div", _hoisted_6, [
                        (_openBlock(), _createElementBlock(_Fragment, null, _renderList(4, (i2) => {
                          return _createElementVNode("div", {
                            key: i2 + '_mock_text',
                            class: _normalizeClass([`${_ctx.locked ? 'image-grey' : 'image-skeleton'}`, "opa-03 mt-2 br-4"]),
                            style: {"width":"100%"}
                          }, "   ", 2)
                        }), 64)),
                        _createElementVNode("div", {
                          class: _normalizeClass([`${_ctx.locked ? 'image-grey' : 'image-skeleton'}`, "opa-03 mt-2 br-4"]),
                          style: {"width":"80%"}
                        }, "   ", 2)
                      ]),
                      _createElementVNode("div", _hoisted_7, [
                        (_openBlock(), _createElementBlock(_Fragment, null, _renderList(3, (i2) => {
                          return _createElementVNode("div", {
                            key: i2 + '_guidance_list',
                            class: "guidance-list opa-08"
                          }, _cache[0] || (_cache[0] = [
                            _createElementVNode("div", { class: "index" }, " ", -1),
                            _createElementVNode("div", { class: "label-list" }, " ", -1)
                          ]))
                        }), 64))
                      ])
                    ])
                  ], 2)
                ]),
                _: 1
              }),
              _createElementVNode("div", _hoisted_8, [
                (_openBlock(), _createElementBlock(_Fragment, null, _renderList(2, (i2) => {
                  return _createElementVNode("div", {
                    key: i2 + '_mock_text',
                    class: _normalizeClass([`${_ctx.locked ? 'image-grey' : 'image-skeleton'}`, "opa-03 mt-2 br-4"]),
                    style: {"width":"100%"}
                  }, "   ", 2)
                }), 64)),
                _createElementVNode("div", {
                  class: _normalizeClass([`${_ctx.locked ? 'image-grey' : 'image-skeleton'}`, "opa-03 mt-2 br-4"]),
                  style: {"width":"80%"}
                }, "   ", 2)
              ])
            ])
          ])
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_9, [
          _createElementVNode("div", _hoisted_10, [
            _createElementVNode("div", _hoisted_11, _toDisplayString(_ctx.$t("coreValuesAnalyzer.Skills_and_Development_Guidance")), 1),
            _createElementVNode("div", _hoisted_12, _toDisplayString(_ctx.$t("coreValuesAnalyzer.In_order_for_your")), 1)
          ]),
          _createElementVNode("div", _hoisted_13, [
            _createElementVNode("div", _hoisted_14, [
              _createVNode(_component_masonry_wall, {
                items: _ctx.skillsData.skills,
                "ssr-columns": 1,
                "column-width": 460,
                gap: 32
              }, {
                default: _withCtx(({ item, index }) => [
                  _createElementVNode("div", {
                    class: _normalizeClass(["guidance-card", index])
                  }, [
                    _createElementVNode("div", _hoisted_15, _toDisplayString(item.coreValue), 1),
                    _createElementVNode("div", _hoisted_16, _toDisplayString(item.skillLabel), 1),
                    _createElementVNode("div", _hoisted_17, _toDisplayString(item.skillDescription), 1),
                    _createElementVNode("div", _hoisted_18, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.skillNotes, (note, index2) => {
                        return (_openBlock(), _createElementBlock("div", {
                          key: index + '_' + index2,
                          class: "guidance-list"
                        }, [
                          _createElementVNode("div", _hoisted_19, _toDisplayString(index2 + 1), 1),
                          _createElementVNode("div", _hoisted_20, _toDisplayString(note), 1)
                        ]))
                      }), 128))
                    ])
                  ], 2)
                ]),
                _: 1
              }, 8, ["items"])
            ])
          ])
        ])),
    _createElementVNode("div", _hoisted_21, _toDisplayString(_ctx.$t("coreValuesAnalyzer.By_veloping_and_supporting")) + " " + _toDisplayString(_ctx.$t("coreValuesAnalyzer.Leaders_can_encourage_and")), 1)
  ], 64))
}