import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, normalizeStyle as _normalizeStyle, createStaticVNode as _createStaticVNode } from "vue"
import _imports_0 from '@/assets/image/AnalysisSectionIcons/clarity_black.png'
import _imports_1 from '@/assets/image/AnalysisSectionIcons/bestpractice_black.png'
import _imports_2 from '@/assets/image/AnalysisSectionIcons/practical_black.png'
import _imports_3 from '@/assets/image/AnalysisSectionIcons/inspiring_black.png'
import _imports_4 from '@/assets/image/AnalysisSectionIcons/ic_clarity.png'
import _imports_5 from '@/assets/image/AnalysisSectionIcons/ic_bestpractice.png'
import _imports_6 from '@/assets/image/AnalysisSectionIcons/ic_practical.png'
import _imports_7 from '@/assets/image/AnalysisSectionIcons/ic_inspirational.png'


const _hoisted_1 = { class: "box-title audit-header" }
const _hoisted_2 = {
  key: 0,
  class: "border-box"
}
const _hoisted_3 = {
  class: "flex-mid relative",
  style: {"height":"15rem"}
}
const _hoisted_4 = {
  class: "mx-auto",
  style: {"max-width":"72rem","width":"100%"}
}
const _hoisted_5 = { class: "fs-14 center mt-13" }
const _hoisted_6 = {
  key: 1,
  class: "border-box audit-summary-content"
}
const _hoisted_7 = { class: "chart-name" }
const _hoisted_8 = { class: "table-summary" }
const _hoisted_9 = { class: "flex-mid pt-12 pb-6" }
const _hoisted_10 = { class: "flex-mid pt-12 pb-6" }
const _hoisted_11 = { class: "flex-mid pt-12 pb-6" }
const _hoisted_12 = { class: "flex-mid pt-12 pb-6" }
const _hoisted_13 = { class: "right pr-14" }
const _hoisted_14 = {
  key: 2,
  class: "core-value-conten"
}
const _hoisted_15 = { class: "" }
const _hoisted_16 = {
  class: "border-box",
  style: {"padding":"0"}
}
const _hoisted_17 = {
  class: "chart-topper",
  style: {"padding":"2.4rem 4.2rem"}
}
const _hoisted_18 = { class: "chart-box" }
const _hoisted_19 = { class: "chart-name" }
const _hoisted_20 = { class: "chart-box" }
const _hoisted_21 = { class: "chart-name" }
const _hoisted_22 = { class: "chart-box" }
const _hoisted_23 = { class: "chart-name" }
const _hoisted_24 = { class: "chart-box" }
const _hoisted_25 = { class: "chart-name" }
const _hoisted_26 = { class: "core-value-content" }
const _hoisted_27 = { class: "clartiy" }
const _hoisted_28 = { class: "header-summary flex-center" }
const _hoisted_29 = { class: "text ml-12" }
const _hoisted_30 = { class: "text-header" }
const _hoisted_31 = { class: "best-practice mt-32" }
const _hoisted_32 = { class: "header-summary flex-center" }
const _hoisted_33 = { class: "text ml-12" }
const _hoisted_34 = { class: "text-header" }
const _hoisted_35 = { class: "practical" }
const _hoisted_36 = { class: "header-summary flex-center" }
const _hoisted_37 = { class: "text ml-12" }
const _hoisted_38 = { class: "text-header" }
const _hoisted_39 = { class: "inspiring mt-32" }
const _hoisted_40 = { class: "header-summary flex-center" }
const _hoisted_41 = { class: "text ml-12" }
const _hoisted_42 = { class: "text-header" }
const _hoisted_43 = { class: "alternative-options-container" }
const _hoisted_44 = { class: "fs-18 fw-700" }
const _hoisted_45 = { class: "index" }
const _hoisted_46 = {
  key: 3,
  class: "core-value-conten"
}
const _hoisted_47 = { class: "box-title" }
const _hoisted_48 = {
  class: "border-box",
  style: {"padding":"0"}
}
const _hoisted_49 = {
  class: "chart-topper",
  style: {"padding":"2.4rem 4.2rem"}
}
const _hoisted_50 = { class: "chart-box" }
const _hoisted_51 = { class: "chart-name" }
const _hoisted_52 = { class: "chart-box" }
const _hoisted_53 = { class: "chart-name" }
const _hoisted_54 = { class: "chart-box" }
const _hoisted_55 = { class: "chart-name" }
const _hoisted_56 = { class: "chart-box" }
const _hoisted_57 = { class: "chart-name" }
const _hoisted_58 = { class: "core-value-content" }
const _hoisted_59 = { class: "clartiy" }
const _hoisted_60 = { class: "header-summary flex-center" }
const _hoisted_61 = { class: "text ml-12" }
const _hoisted_62 = { class: "result-header" }
const _hoisted_63 = { class: "text-header" }
const _hoisted_64 = { class: "status-label" }
const _hoisted_65 = {
  key: 0,
  class: "bar-container flex-center"
}
const _hoisted_66 = {
  key: 0,
  class: "fs-12 fc-696C80 ml-auto"
}
const _hoisted_67 = {
  key: 1,
  class: "fs-12 ml-auto"
}
const _hoisted_68 = { class: "content-text" }
const _hoisted_69 = { class: "best-practice mt-32" }
const _hoisted_70 = { class: "header-summary flex-center" }
const _hoisted_71 = { class: "text ml-12" }
const _hoisted_72 = { class: "result-header" }
const _hoisted_73 = { class: "text-header" }
const _hoisted_74 = { class: "status-label" }
const _hoisted_75 = {
  key: 0,
  class: "bar-container flex-center"
}
const _hoisted_76 = {
  key: 0,
  class: "fs-12 fc-696C80 ml-auto"
}
const _hoisted_77 = {
  key: 1,
  class: "fs-12 ml-auto"
}
const _hoisted_78 = { class: "content-text" }
const _hoisted_79 = { class: "practical" }
const _hoisted_80 = { class: "header-summary flex-center" }
const _hoisted_81 = { class: "text ml-12" }
const _hoisted_82 = { class: "result-header" }
const _hoisted_83 = { class: "text-header" }
const _hoisted_84 = { class: "status-label" }
const _hoisted_85 = {
  key: 0,
  class: "bar-container flex-center"
}
const _hoisted_86 = {
  key: 0,
  class: "fs-12 fc-696C80 ml-auto"
}
const _hoisted_87 = {
  key: 1,
  class: "fs-12 ml-auto"
}
const _hoisted_88 = { class: "content-text" }
const _hoisted_89 = { class: "inspiring mt-32" }
const _hoisted_90 = { class: "header-summary flex-center" }
const _hoisted_91 = { class: "text ml-12" }
const _hoisted_92 = { class: "result-header" }
const _hoisted_93 = { class: "text-header" }
const _hoisted_94 = { class: "status-label" }
const _hoisted_95 = {
  key: 0,
  class: "bar-container flex-center"
}
const _hoisted_96 = {
  key: 0,
  class: "fs-12 fc-696C80 ml-auto"
}
const _hoisted_97 = {
  key: 1,
  class: "fs-12 ml-auto"
}
const _hoisted_98 = { class: "content-text" }
const _hoisted_99 = { class: "alternative-options-section mt-16" }
const _hoisted_100 = { class: "fs-18 fw-700" }
const _hoisted_101 = { class: "alternative-options-container" }
const _hoisted_102 = { class: "index" }
const _hoisted_103 = { class: "core-value-label" }
const _hoisted_104 = { class: "value-title" }
const _hoisted_105 = { class: "value-desc" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_progress = _resolveComponent("el-progress")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.$t("coreValuesAnalyzer.Audit_Summary")), 1),
    (_ctx.loadingAllData)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_component_el_progress, {
                "stroke-width": 24,
                percentage: _ctx.loadingPercentage,
                color: _ctx.companyColor,
                "show-text": false
              }, null, 8, ["percentage", "color"]),
              _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.$t("coreValuesAnalyzer.Auditing_you_values")), 1)
            ])
          ])
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_6, [
          _createElementVNode("div", null, [
            (_ctx.overAllData.overAllPercentage)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 0,
                  class: _normalizeClass(["chart-box", `bg-${_ctx.overAllPercentageStyle(
          _ctx.overAllData.overAllPercentage
        )}-soft`])
                }, [
                  _createElementVNode("div", {
                    class: _normalizeClass(["chart-canvas-box", `bg-${_ctx.overAllPercentageStyle(
            _ctx.overAllData.overAllPercentage
          )}-soft`])
                  }, [
                    _createVNode(_component_el_progress, {
                      type: "circle",
                      class: "absolute-mid",
                      "show-text": false,
                      "stroke-linecap": "square",
                      color: _ctx.overAllPercentageColorCode(_ctx.overAllData.overAllPercentage),
                      width: 180,
                      height: 180,
                      "stroke-width": 6,
                      percentage: _ctx.overAllData.overAllPercentage
                    }, null, 8, ["color", "percentage"]),
                    _createElementVNode("div", {
                      class: _normalizeClass(["absolute-mid label-inner flex-mid", `fc-${_ctx.overAllPercentageStyle(
              _ctx.overAllData.overAllPercentage
            )}-results`])
                    }, [
                      _createElementVNode("div", null, [
                        _createElementVNode("div", null, _toDisplayString(_ctx.overAllData.overAllPercentage) + "%", 1),
                        _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.$t("coreValuesAnalyzer.Overall")), 1)
                      ])
                    ], 2)
                  ], 2)
                ], 2))
              : _createCommentVNode("", true)
          ]),
          _cache[1] || (_cache[1] = _createElementVNode("div", { class: "line-y max-m-none" }, null, -1)),
          _createElementVNode("div", null, [
            _createElementVNode("div", _hoisted_8, [
              _cache[0] || (_cache[0] = _createElementVNode("div", null, " ", -1)),
              _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.$t("coreValuesAnalyzer.Clarity")), 1),
              _createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.$t("coreValuesAnalyzer.Practical")), 1),
              _createElementVNode("div", _hoisted_11, _toDisplayString(_ctx.$t("coreValuesAnalyzer.Best_Practice")), 1),
              _createElementVNode("div", _hoisted_12, _toDisplayString(_ctx.$t("coreValuesAnalyzer.Inspiring")), 1)
            ]),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.overAllData.dataByCoreValue, (value) => {
              return (_openBlock(), _createElementBlock("div", {
                key: value.id + '_header',
                class: "flex-mid table-summary"
              }, [
                _createElementVNode("div", _hoisted_13, _toDisplayString(value.coreValueLabel), 1),
                _createElementVNode("div", {
                  class: _normalizeClass(["flex-mid fs-12 cell-percentage", `fc-${_ctx.overAllPercentageStyle(value.clarity.percentage)}-font`]),
                  style: _normalizeStyle(`background-color: ${_ctx.overAllPercentageColorCode(
            value.clarity.percentage || 0
          )}66;`)
                }, _toDisplayString(_ctx.checkingNaN(value.clarity.percentage)), 7),
                _createElementVNode("div", {
                  class: _normalizeClass(["flex-mid fs-12 cell-percentage", `fc-${_ctx.overAllPercentageStyle(
            value.practical.percentage
          )}-font`]),
                  style: _normalizeStyle(`background-color: ${_ctx.overAllPercentageColorCode(
            value.practical.percentage || 0
          )}66;`)
                }, _toDisplayString(_ctx.checkingNaN(value.practical.percentage)), 7),
                _createElementVNode("div", {
                  class: _normalizeClass(["flex-mid fs-12 cell-percentage", `fc-${_ctx.overAllPercentageStyle(
            value.bestPractice.percentage
          )}-font`]),
                  style: _normalizeStyle(`background-color: ${_ctx.overAllPercentageColorCode(
            value.bestPractice.percentage || 0
          )}66;`)
                }, _toDisplayString(_ctx.checkingNaN(value.bestPractice.percentage)), 7),
                _createElementVNode("div", {
                  class: _normalizeClass(["flex-mid fs-12 cell-percentage", `fc-${_ctx.overAllPercentageStyle(
            value.inspiring.percentage
          )}-font`]),
                  style: _normalizeStyle(`background-color: ${_ctx.overAllPercentageColorCode(
            value.inspiring.percentage || 0
          )}66;`)
                }, _toDisplayString(_ctx.checkingNaN(value.inspiring.percentage)), 7)
              ]))
            }), 128))
          ])
        ])),
    (_ctx.loadingAllData)
      ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
          _createElementVNode("div", _hoisted_15, [
            _cache[17] || (_cache[17] = _createElementVNode("div", { class: "box-title" }, [
              _createElementVNode("div", {
                class: "image-skeleton opa-07 mt-2 br-4",
                style: {"width":"200px"}
              }, "   ")
            ], -1)),
            _createElementVNode("div", _hoisted_16, [
              _createElementVNode("div", _hoisted_17, [
                _createElementVNode("div", _hoisted_18, [
                  _cache[2] || (_cache[2] = _createElementVNode("div", { class: "chart-canvas-box bg-gray-soft" }, [
                    _createElementVNode("div", { class: "absolute-mid fs-18 fw-700 fc-gray-results" }, "   ")
                  ], -1)),
                  _createElementVNode("div", _hoisted_19, _toDisplayString(_ctx.$t("coreValuesAnalyzer.Clarity")), 1)
                ]),
                _createElementVNode("div", _hoisted_20, [
                  _cache[3] || (_cache[3] = _createElementVNode("div", { class: "chart-canvas-box bg-gray-soft" }, [
                    _createElementVNode("div", { class: "absolute-mid fs-18 fw-700 fc-gray-results" }, "   ")
                  ], -1)),
                  _createElementVNode("div", _hoisted_21, _toDisplayString(_ctx.$t("coreValuesAnalyzer.Practical")), 1)
                ]),
                _createElementVNode("div", _hoisted_22, [
                  _cache[4] || (_cache[4] = _createElementVNode("div", { class: "chart-canvas-box bg-gray-soft" }, [
                    _createElementVNode("div", { class: "absolute-mid fs-18 fw-700 fc-gray-results" }, "   ")
                  ], -1)),
                  _createElementVNode("div", _hoisted_23, _toDisplayString(_ctx.$t("coreValuesAnalyzer.Best_Practice")), 1)
                ]),
                _createElementVNode("div", _hoisted_24, [
                  _cache[5] || (_cache[5] = _createElementVNode("div", { class: "chart-canvas-box bg-gray-soft" }, [
                    _createElementVNode("div", { class: "absolute-mid fs-18 fw-700 fc-gray-results" }, "   ")
                  ], -1)),
                  _createElementVNode("div", _hoisted_25, _toDisplayString(_ctx.$t("coreValuesAnalyzer.Inspiring")), 1)
                ])
              ]),
              _cache[15] || (_cache[15] = _createElementVNode("div", { class: "line-x" }, null, -1)),
              _createElementVNode("div", _hoisted_26, [
                _createElementVNode("div", null, [
                  _createElementVNode("div", _hoisted_27, [
                    _createElementVNode("div", _hoisted_28, [
                      _cache[6] || (_cache[6] = _createElementVNode("div", { class: "icon flex-mid" }, [
                        _createElementVNode("img", {
                          src: _imports_0,
                          alt: "clarity_black"
                        })
                      ], -1)),
                      _createElementVNode("div", _hoisted_29, _toDisplayString(_ctx.$t("coreValuesAnalyzer.Clarity")) + " - ? / 100 ", 1)
                    ]),
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList([
                  _ctx.$t('coreValuesAnalyzer.Simple_Language'),
                  _ctx.$t('coreValuesAnalyzer.Potential_Misunderstanding'),
                  _ctx.$t('coreValuesAnalyzer.Consistency'),
                ], (content, index2) => {
                      return (_openBlock(), _createElementBlock("div", {
                        key: 'data_content_clartiy_mock' + index2,
                        class: "content-summary"
                      }, [
                        _createElementVNode("div", _hoisted_30, _toDisplayString(content), 1),
                        _cache[7] || (_cache[7] = _createStaticVNode("<div class=\"percentage-line\" data-v-7a379796></div><div class=\"status-label image-skeleton opa-07 mt-2 br-4\" style=\"width:100px;\" data-v-7a379796>   </div><div class=\"content-text image-skeleton opa-03 mt-2 br-4\" data-v-7a379796>   </div><div class=\"content-text image-skeleton opa-03 mt-2 br-4\" data-v-7a379796>   </div><div class=\"content-text image-skeleton opa-03 mt-2 br-4\" style=\"width:80%;\" data-v-7a379796>   </div>", 5))
                      ]))
                    }), 128))
                  ]),
                  _createElementVNode("div", _hoisted_31, [
                    _createElementVNode("div", _hoisted_32, [
                      _cache[8] || (_cache[8] = _createElementVNode("div", { class: "icon flex-mid" }, [
                        _createElementVNode("img", {
                          src: _imports_1,
                          alt: "bestpractice_black"
                        })
                      ], -1)),
                      _createElementVNode("div", _hoisted_33, _toDisplayString(_ctx.$t("coreValuesAnalyzer.Best_Practice")) + " - ? / 100 ", 1)
                    ]),
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList([
                  _ctx.$t('coreValuesAnalyzer.Not_Permission_To_Play'),
                ], (content, index2) => {
                      return (_openBlock(), _createElementBlock("div", {
                        key: 'data_content_best-practice_mock_' + index2,
                        class: "content-summary"
                      }, [
                        _createElementVNode("div", _hoisted_34, _toDisplayString(content), 1),
                        _cache[9] || (_cache[9] = _createStaticVNode("<div class=\"percentage-line\" data-v-7a379796></div><div class=\"status-label image-skeleton opa-07 mt-2 br-4\" style=\"width:100px;\" data-v-7a379796>   </div><div class=\"content-text image-skeleton opa-03 mt-2 br-4\" data-v-7a379796>   </div><div class=\"content-text image-skeleton opa-03 mt-2 br-4\" data-v-7a379796>   </div><div class=\"content-text image-skeleton opa-03 mt-2 br-4\" style=\"width:80%;\" data-v-7a379796>   </div>", 5))
                      ]))
                    }), 128))
                  ])
                ]),
                _createElementVNode("div", null, [
                  _createElementVNode("div", _hoisted_35, [
                    _createElementVNode("div", _hoisted_36, [
                      _cache[10] || (_cache[10] = _createElementVNode("div", { class: "icon flex-mid" }, [
                        _createElementVNode("img", {
                          src: _imports_2,
                          alt: "practical_black"
                        })
                      ], -1)),
                      _createElementVNode("div", _hoisted_37, _toDisplayString(_ctx.$t("coreValuesAnalyzer.Practical")) + " - ? / 100 ", 1)
                    ]),
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList([
                  _ctx.$t('coreValuesAnalyzer.Actionable'),
                  _ctx.$t('coreValuesAnalyzer.Easy_To_Reinforce'),
                  _ctx.$t('coreValuesAnalyzer.Measureable'),
                ], (content, index2) => {
                      return (_openBlock(), _createElementBlock("div", {
                        key: 'data_content_practical_mock_' + index2,
                        class: "content-summary"
                      }, [
                        _createElementVNode("div", _hoisted_38, _toDisplayString(content), 1),
                        _cache[11] || (_cache[11] = _createStaticVNode("<div class=\"percentage-line\" data-v-7a379796></div><div class=\"status-label image-skeleton opa-07 mt-2 br-4\" style=\"width:100px;\" data-v-7a379796>   </div><div class=\"content-text image-skeleton opa-03 mt-2 br-4\" data-v-7a379796>   </div><div class=\"content-text image-skeleton opa-03 mt-2 br-4\" data-v-7a379796>   </div><div class=\"content-text image-skeleton opa-03 mt-2 br-4\" style=\"width:80%;\" data-v-7a379796>   </div>", 5))
                      ]))
                    }), 128))
                  ]),
                  _createElementVNode("div", _hoisted_39, [
                    _createElementVNode("div", _hoisted_40, [
                      _cache[12] || (_cache[12] = _createElementVNode("div", { class: "icon flex-mid" }, [
                        _createElementVNode("img", {
                          src: _imports_3,
                          alt: "inspiring_black"
                        })
                      ], -1)),
                      _createElementVNode("div", _hoisted_41, _toDisplayString(_ctx.$t("coreValuesAnalyzer.Inspiring")) + " - ? / 100 ", 1)
                    ]),
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList([
                  _ctx.$t('coreValuesAnalyzer.Sense_Of_Pride'),
                  _ctx.$t('coreValuesAnalyzer.Positive_Impact'),
                ], (content, index2) => {
                      return (_openBlock(), _createElementBlock("div", {
                        key: 'data_content_inspiring_mock_' + index2,
                        class: "content-summary"
                      }, [
                        _createElementVNode("div", _hoisted_42, _toDisplayString(content), 1),
                        _cache[13] || (_cache[13] = _createStaticVNode("<div class=\"percentage-line\" data-v-7a379796></div><div class=\"status-label image-skeleton opa-07 mt-2 br-4\" style=\"width:100px;\" data-v-7a379796>   </div><div class=\"content-text image-skeleton opa-03 mt-2 br-4\" data-v-7a379796>   </div><div class=\"content-text image-skeleton opa-03 mt-2 br-4\" data-v-7a379796>   </div><div class=\"content-text image-skeleton opa-03 mt-2 br-4\" style=\"width:80%;\" data-v-7a379796>   </div>", 5))
                      ]))
                    }), 128))
                  ])
                ])
              ]),
              _cache[16] || (_cache[16] = _createElementVNode("div", { class: "line-x" }, null, -1)),
              _createElementVNode("div", _hoisted_43, [
                _createElementVNode("div", _hoisted_44, _toDisplayString(_ctx.$t("coreValuesAnalyzer.Alternative_Options")), 1),
                (_openBlock(), _createElementBlock(_Fragment, null, _renderList(['', '', ''], (option, index2) => {
                  return _createElementVNode("div", {
                    class: _normalizeClass(["optional-text", index2 == 0 ? 'mt-12' : 'mt-24']),
                    key: 'alternative_options_' + index2
                  }, [
                    _createElementVNode("div", _hoisted_45, _toDisplayString(index2 + 1), 1),
                    _cache[14] || (_cache[14] = _createElementVNode("div", { class: "core-value-label" }, [
                      _createElementVNode("div", { class: "image-skeleton opa-03 mt-2 br-4" }, " "),
                      _createElementVNode("div", { class: "image-skeleton opa-03 mt-2 br-4" }, " "),
                      _createElementVNode("div", {
                        class: "image-skeleton opa-03 mt-2 br-4",
                        style: {"width":"90%"}
                      }, "   ")
                    ], -1))
                  ], 2)
                }), 64))
              ])
            ])
          ])
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_46, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.overAllData.dataByCoreValue, (coreValue, index) => {
            return (_openBlock(), _createElementBlock("div", {
              key: 'core_value_content_' + index,
              class: ""
            }, [
              _createElementVNode("div", _hoisted_47, _toDisplayString(coreValue.coreValueLabel), 1),
              _createElementVNode("div", _hoisted_48, [
                _createElementVNode("div", _hoisted_49, [
                  _createElementVNode("div", _hoisted_50, [
                    _createElementVNode("div", {
                      class: _normalizeClass(["chart-canvas-box", `bg-${_ctx.overAllPercentageStyle(
                coreValue.clarity.percentage
              )}-soft`])
                    }, [
                      _createVNode(_component_el_progress, {
                        type: "circle",
                        class: "absolute-mid",
                        "show-text": false,
                        "stroke-linecap": "square",
                        color: 
                  _ctx.overAllPercentageColorCode(coreValue.clarity.percentage)
                ,
                        width: 64,
                        height: 64,
                        "stroke-width": 6,
                        percentage: coreValue.clarity.percentage
                      }, null, 8, ["color", "percentage"]),
                      _createElementVNode("div", {
                        class: _normalizeClass(["absolute-mid fs-18 fw-700", `fc-${_ctx.overAllPercentageStyle(
                  coreValue.clarity.percentage
                )}-results`])
                      }, _toDisplayString(_ctx.checkingNaN(coreValue.clarity.percentage)), 3)
                    ], 2),
                    _createElementVNode("div", _hoisted_51, _toDisplayString(_ctx.$t("coreValuesAnalyzer.Clarity")), 1)
                  ]),
                  _createElementVNode("div", _hoisted_52, [
                    _createElementVNode("div", {
                      class: _normalizeClass(["chart-canvas-box", `bg-${_ctx.overAllPercentageStyle(
                coreValue.practical.percentage
              )}-soft`])
                    }, [
                      _createVNode(_component_el_progress, {
                        type: "circle",
                        class: "absolute-mid",
                        "show-text": false,
                        "stroke-linecap": "square",
                        color: 
                  _ctx.overAllPercentageColorCode(coreValue.practical.percentage)
                ,
                        width: 64,
                        height: 64,
                        "stroke-width": 6,
                        percentage: coreValue.practical.percentage
                      }, null, 8, ["color", "percentage"]),
                      _createElementVNode("div", {
                        class: _normalizeClass(["absolute-mid fs-18 fw-700", `fc-${_ctx.overAllPercentageStyle(
                  coreValue.practical.percentage
                )}-results`])
                      }, _toDisplayString(_ctx.checkingNaN(coreValue.practical.percentage)), 3)
                    ], 2),
                    _createElementVNode("div", _hoisted_53, _toDisplayString(_ctx.$t("coreValuesAnalyzer.Practical")), 1)
                  ]),
                  _createElementVNode("div", _hoisted_54, [
                    _createElementVNode("div", {
                      class: _normalizeClass(["chart-canvas-box", `bg-${_ctx.overAllPercentageStyle(
                coreValue.bestPractice.percentage
              )}-soft`])
                    }, [
                      _createVNode(_component_el_progress, {
                        type: "circle",
                        class: "absolute-mid",
                        "show-text": false,
                        "stroke-linecap": "square",
                        color: 
                  _ctx.overAllPercentageColorCode(coreValue.bestPractice.percentage)
                ,
                        width: 64,
                        height: 64,
                        "stroke-width": 6,
                        percentage: coreValue.bestPractice.percentage
                      }, null, 8, ["color", "percentage"]),
                      _createElementVNode("div", {
                        class: _normalizeClass(["absolute-mid fs-18 fw-700", `fc-${_ctx.overAllPercentageStyle(
                  coreValue.bestPractice.percentage
                )}-results`])
                      }, _toDisplayString(_ctx.checkingNaN(coreValue.bestPractice.percentage)), 3)
                    ], 2),
                    _createElementVNode("div", _hoisted_55, _toDisplayString(_ctx.$t("coreValuesAnalyzer.Best_Practice")), 1)
                  ]),
                  _createElementVNode("div", _hoisted_56, [
                    _createElementVNode("div", {
                      class: _normalizeClass(["chart-canvas-box", `bg-${_ctx.overAllPercentageStyle(
                coreValue.inspiring.percentage
              )}-soft`])
                    }, [
                      _createVNode(_component_el_progress, {
                        type: "circle",
                        class: "absolute-mid",
                        "show-text": false,
                        "stroke-linecap": "square",
                        color: 
                  _ctx.overAllPercentageColorCode(coreValue.inspiring.percentage)
                ,
                        width: 64,
                        height: 64,
                        "stroke-width": 6,
                        percentage: coreValue.inspiring.percentage
                      }, null, 8, ["color", "percentage"]),
                      _createElementVNode("div", {
                        class: _normalizeClass(["absolute-mid fs-18 fw-700", `fc-${_ctx.overAllPercentageStyle(
                  coreValue.inspiring.percentage
                )}-results`])
                      }, _toDisplayString(_ctx.checkingNaN(coreValue.inspiring.percentage)), 3)
                    ], 2),
                    _createElementVNode("div", _hoisted_57, _toDisplayString(_ctx.$t("coreValuesAnalyzer.Inspiring")), 1)
                  ])
                ]),
                _createElementVNode("div", _hoisted_58, [
                  _createElementVNode("div", null, [
                    _createElementVNode("div", _hoisted_59, [
                      _createElementVNode("div", _hoisted_60, [
                        _cache[18] || (_cache[18] = _createElementVNode("div", { class: "icon flex-mid" }, [
                          _createElementVNode("img", {
                            src: _imports_4,
                            alt: "Clarity Icon"
                          })
                        ], -1)),
                        _createElementVNode("div", _hoisted_61, _toDisplayString(_ctx.$t("coreValuesAnalyzer.Clarity")) + " - " + _toDisplayString(_ctx.checkingNaN2(coreValue.clarity.percentage)) + " / 100 ", 1)
                      ]),
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(coreValue.clarity.dataContent, (content, index2) => {
                        return (_openBlock(), _createElementBlock("div", {
                          key: 'data_content_clartiy_' + index2,
                          class: "content-summary"
                        }, [
                          _createElementVNode("div", _hoisted_62, [
                            _createElementVNode("div", _hoisted_63, _toDisplayString(content.header), 1),
                            _createElementVNode("div", _hoisted_64, _toDisplayString(content.statusLabel), 1)
                          ]),
                          (content.percentage !== false)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_65, [
                                _createElementVNode("div", {
                                  class: "bar-box box-left",
                                  style: _normalizeStyle(_ctx.checkOpacityAndColor(content.percentage, 20))
                                }, null, 4),
                                _createElementVNode("div", {
                                  class: "bar-box",
                                  style: _normalizeStyle(_ctx.checkOpacityAndColor(content.percentage, 40))
                                }, null, 4),
                                _createElementVNode("div", {
                                  class: "bar-box",
                                  style: _normalizeStyle(_ctx.checkOpacityAndColor(content.percentage, 60))
                                }, null, 4),
                                _createElementVNode("div", {
                                  class: "bar-box",
                                  style: _normalizeStyle(_ctx.checkOpacityAndColor(content.percentage, 80))
                                }, null, 4),
                                _createElementVNode("div", {
                                  class: "bar-box box-right",
                                  style: _normalizeStyle(_ctx.checkOpacityAndColor(content.percentage, 100))
                                }, null, 4),
                                (content.percentage)
                                  ? (_openBlock(), _createElementBlock("div", _hoisted_66, _toDisplayString(content.percentage) + "% ", 1))
                                  : (_openBlock(), _createElementBlock("div", _hoisted_67, " "))
                              ]))
                            : _createCommentVNode("", true),
                          _createElementVNode("div", _hoisted_68, _toDisplayString(content.content), 1)
                        ]))
                      }), 128))
                    ]),
                    _createElementVNode("div", _hoisted_69, [
                      _createElementVNode("div", _hoisted_70, [
                        _cache[19] || (_cache[19] = _createElementVNode("div", { class: "icon flex-mid" }, [
                          _createElementVNode("img", {
                            src: _imports_5,
                            alt: "Best Practice Icon"
                          })
                        ], -1)),
                        _createElementVNode("div", _hoisted_71, _toDisplayString(_ctx.$t("coreValuesAnalyzer.Best_Practice")) + " - " + _toDisplayString(_ctx.checkingNaN2(coreValue.bestPractice.percentage)) + " / 100 ", 1)
                      ]),
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(coreValue.bestPractice.dataContent, (content, index2) => {
                        return (_openBlock(), _createElementBlock("div", {
                          key: 'data_content_best-practice_' + index2,
                          class: "content-summary"
                        }, [
                          _createElementVNode("div", _hoisted_72, [
                            _createElementVNode("div", _hoisted_73, _toDisplayString(content.header), 1),
                            _createElementVNode("div", _hoisted_74, _toDisplayString(content.statusLabel), 1)
                          ]),
                          (content.percentage !== false)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_75, [
                                _createElementVNode("div", {
                                  class: "bar-box box-left",
                                  style: _normalizeStyle(_ctx.checkOpacityAndColor(content.percentage, 20))
                                }, null, 4),
                                _createElementVNode("div", {
                                  class: "bar-box",
                                  style: _normalizeStyle(_ctx.checkOpacityAndColor(content.percentage, 40))
                                }, null, 4),
                                _createElementVNode("div", {
                                  class: "bar-box",
                                  style: _normalizeStyle(_ctx.checkOpacityAndColor(content.percentage, 60))
                                }, null, 4),
                                _createElementVNode("div", {
                                  class: "bar-box",
                                  style: _normalizeStyle(_ctx.checkOpacityAndColor(content.percentage, 80))
                                }, null, 4),
                                _createElementVNode("div", {
                                  class: "bar-box box-right",
                                  style: _normalizeStyle(_ctx.checkOpacityAndColor(content.percentage, 100))
                                }, null, 4),
                                (content.percentage)
                                  ? (_openBlock(), _createElementBlock("div", _hoisted_76, _toDisplayString(content.percentage) + "% ", 1))
                                  : (_openBlock(), _createElementBlock("div", _hoisted_77, " "))
                              ]))
                            : _createCommentVNode("", true),
                          _createElementVNode("div", _hoisted_78, _toDisplayString(content.content), 1)
                        ]))
                      }), 128))
                    ])
                  ]),
                  _createElementVNode("div", null, [
                    _createElementVNode("div", _hoisted_79, [
                      _createElementVNode("div", _hoisted_80, [
                        _cache[20] || (_cache[20] = _createElementVNode("div", { class: "icon flex-mid" }, [
                          _createElementVNode("img", {
                            src: _imports_6,
                            alt: "Practical Icon"
                          })
                        ], -1)),
                        _createElementVNode("div", _hoisted_81, _toDisplayString(_ctx.$t("coreValuesAnalyzer.Practical")) + " - " + _toDisplayString(_ctx.checkingNaN2(coreValue.practical.percentage)) + " / 100 ", 1)
                      ]),
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(coreValue.practical.dataContent, (content, index2) => {
                        return (_openBlock(), _createElementBlock("div", {
                          key: 'data_content_practical_' + index2,
                          class: "content-summary"
                        }, [
                          _createElementVNode("div", _hoisted_82, [
                            _createElementVNode("div", _hoisted_83, _toDisplayString(content.header), 1),
                            _createElementVNode("div", _hoisted_84, _toDisplayString(content.statusLabel), 1)
                          ]),
                          (content.percentage !== false)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_85, [
                                _createElementVNode("div", {
                                  class: "bar-box box-left",
                                  style: _normalizeStyle(_ctx.checkOpacityAndColor(content.percentage, 20))
                                }, null, 4),
                                _createElementVNode("div", {
                                  class: "bar-box",
                                  style: _normalizeStyle(_ctx.checkOpacityAndColor(content.percentage, 40))
                                }, null, 4),
                                _createElementVNode("div", {
                                  class: "bar-box",
                                  style: _normalizeStyle(_ctx.checkOpacityAndColor(content.percentage, 60))
                                }, null, 4),
                                _createElementVNode("div", {
                                  class: "bar-box",
                                  style: _normalizeStyle(_ctx.checkOpacityAndColor(content.percentage, 80))
                                }, null, 4),
                                _createElementVNode("div", {
                                  class: "bar-box box-right",
                                  style: _normalizeStyle(_ctx.checkOpacityAndColor(content.percentage, 100))
                                }, null, 4),
                                (content.percentage)
                                  ? (_openBlock(), _createElementBlock("div", _hoisted_86, _toDisplayString(content.percentage) + "% ", 1))
                                  : (_openBlock(), _createElementBlock("div", _hoisted_87, " "))
                              ]))
                            : _createCommentVNode("", true),
                          _createElementVNode("div", _hoisted_88, _toDisplayString(content.content), 1)
                        ]))
                      }), 128))
                    ]),
                    _createElementVNode("div", _hoisted_89, [
                      _createElementVNode("div", _hoisted_90, [
                        _cache[21] || (_cache[21] = _createElementVNode("div", { class: "icon flex-mid" }, [
                          _createElementVNode("img", {
                            src: _imports_7,
                            alt: "Inspirational Icon"
                          })
                        ], -1)),
                        _createElementVNode("div", _hoisted_91, _toDisplayString(_ctx.$t("coreValuesAnalyzer.Inspiring")) + " - " + _toDisplayString(_ctx.checkingNaN2(coreValue.inspiring.percentage)) + " / 100 ", 1)
                      ]),
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(coreValue.inspiring.dataContent, (content, index2) => {
                        return (_openBlock(), _createElementBlock("div", {
                          key: 'data_content_inspiring_' + index2,
                          class: "content-summary"
                        }, [
                          _createElementVNode("div", _hoisted_92, [
                            _createElementVNode("div", _hoisted_93, _toDisplayString(content.header), 1),
                            _createElementVNode("div", _hoisted_94, _toDisplayString(content.statusLabel), 1)
                          ]),
                          (content.percentage !== false)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_95, [
                                _createElementVNode("div", {
                                  class: "bar-box box-left",
                                  style: _normalizeStyle(_ctx.checkOpacityAndColor(content.percentage, 20))
                                }, null, 4),
                                _createElementVNode("div", {
                                  class: "bar-box",
                                  style: _normalizeStyle(_ctx.checkOpacityAndColor(content.percentage, 40))
                                }, null, 4),
                                _createElementVNode("div", {
                                  class: "bar-box",
                                  style: _normalizeStyle(_ctx.checkOpacityAndColor(content.percentage, 60))
                                }, null, 4),
                                _createElementVNode("div", {
                                  class: "bar-box",
                                  style: _normalizeStyle(_ctx.checkOpacityAndColor(content.percentage, 80))
                                }, null, 4),
                                _createElementVNode("div", {
                                  class: "bar-box box-right",
                                  style: _normalizeStyle(_ctx.checkOpacityAndColor(content.percentage, 100))
                                }, null, 4),
                                (content.percentage)
                                  ? (_openBlock(), _createElementBlock("div", _hoisted_96, _toDisplayString(content.percentage) + "% ", 1))
                                  : (_openBlock(), _createElementBlock("div", _hoisted_97, " "))
                              ]))
                            : _createCommentVNode("", true),
                          _createElementVNode("div", _hoisted_98, _toDisplayString(content.content), 1)
                        ]))
                      }), 128))
                    ])
                  ])
                ]),
                _createElementVNode("div", _hoisted_99, [
                  _createElementVNode("div", _hoisted_100, _toDisplayString(_ctx.$t("coreValuesAnalyzer.Alternative_Options")), 1),
                  _createElementVNode("div", _hoisted_101, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(coreValue.alternativeOptions, (option, index2) => {
                      return (_openBlock(), _createElementBlock("div", {
                        class: "optional-text",
                        key: 'alternative_options_' + index2
                      }, [
                        _createElementVNode("div", _hoisted_102, _toDisplayString(index2 + 1), 1),
                        _createElementVNode("div", _hoisted_103, [
                          _createElementVNode("div", _hoisted_104, _toDisplayString(option.header), 1),
                          _createElementVNode("div", _hoisted_105, _toDisplayString(option.alternativeLabel), 1)
                        ])
                      ]))
                    }), 128))
                  ])
                ])
              ])
            ]))
          }), 128))
        ]))
  ], 64))
}