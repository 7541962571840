import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelText as _vModelText, withKeys as _withKeys, withDirectives as _withDirectives, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeStyle as _normalizeStyle, createBlock as _createBlock } from "vue"
import _imports_0 from '@/assets/image/coreValuesLanding/Preview_1_Audit_Values@2x.png'
import _imports_1 from '@/assets/image/coreValuesLanding/Preview_2_Skills_Development@2x.png'
import _imports_2 from '@/assets/image/coreValuesLanding/Preview_3_Survey_Questions@2x.png'
import _imports_3 from '@/assets/image/coreValuesLanding/Preview_4_Learning_Content@2x.png'
import _imports_4 from '@/assets/icons/lock_black_20dp.png'
import _imports_5 from '@/assets/icons/download_24px@2x.png'
import _imports_6 from '@/assets/image/logo_myculture_pdf.png'


const _hoisted_1 = { class: "hero-section" }
const _hoisted_2 = { class: "input-section-container" }
const _hoisted_3 = { class: "fs-32 fw-900 center pt-32" }
const _hoisted_4 = { class: "fs-16 mt-24 center" }
const _hoisted_5 = { class: "fs-16 opa-05 mt-26" }
const _hoisted_6 = { class: "min-m-flex mt-12 input-section" }
const _hoisted_7 = {
  class: "mr-16 input-box",
  style: {}
}
const _hoisted_8 = { class: "index" }
const _hoisted_9 = ["onUpdate:modelValue", "onKeyup", "id"]
const _hoisted_10 = {
  key: 0,
  class: "btn02 btn-50 x-btn-primary flex-mid max-m-mt-24 anylze-btn",
  style: {"background-color":"#dedede","cursor":"wait"}
}
const _hoisted_11 = { class: "tags-container" }
const _hoisted_12 = ["onClick"]
const _hoisted_13 = {
  class: "box-card detail body-width-reponsive-max mt-64 mb-32",
  style: {"margin-bottom":"64px"}
}
const _hoisted_14 = {
  key: 0,
  class: "what-you-get-section-container"
}
const _hoisted_15 = {
  class: "mt-12 pt-42 pb-42",
  style: {"border-radius":"6px","border":"1px solid #e2e2e2"}
}
const _hoisted_16 = { class: "grid-container" }
const _hoisted_17 = { class: "grid-item start-2" }
const _hoisted_18 = { class: "title" }
const _hoisted_19 = { class: "description" }
const _hoisted_20 = { class: "grid-item" }
const _hoisted_21 = { class: "title" }
const _hoisted_22 = { class: "description" }
const _hoisted_23 = { class: "grid-item start-6" }
const _hoisted_24 = { class: "title" }
const _hoisted_25 = { class: "description" }
const _hoisted_26 = { class: "grid-item" }
const _hoisted_27 = { class: "title" }
const _hoisted_28 = { class: "description" }
const _hoisted_29 = {
  key: 1,
  class: "tab-section-container"
}
const _hoisted_30 = { class: "analysis-tab-container" }
const _hoisted_31 = ["onClick"]
const _hoisted_32 = {
  key: 0,
  src: _imports_4,
  alt: "icons_lock_black_20dp",
  class: "mr-8"
}
const _hoisted_33 = { class: "min-m-none" }
const _hoisted_34 = {
  key: 2,
  class: "analysing-results-section"
}
const _hoisted_35 = { ref: "audit_section" }
const _hoisted_36 = {
  key: 0,
  class: "locked-container border-box min-m-flex-center"
}
const _hoisted_37 = { class: "flex-mid" }
const _hoisted_38 = ["src"]
const _hoisted_39 = { class: "" }
const _hoisted_40 = { class: "flex-center" }
const _hoisted_41 = ["src"]
const _hoisted_42 = { class: "fs-24 fw-700" }
const _hoisted_43 = { class: "min-m-flex-center max-m-flex-mid mt-18" }
const _hoisted_44 = { class: "" }
const _hoisted_45 = { class: "" }
const _hoisted_46 = { ref: "skills_section" }
const _hoisted_47 = { ref: "questions_section" }
const _hoisted_48 = { ref: "microlearning_section" }
const _hoisted_49 = { class: "locked-container border-box" }
const _hoisted_50 = { class: "" }
const _hoisted_51 = {
  class: "",
  style: {"text-align":"center"}
}
const _hoisted_52 = { class: "box-title" }
const _hoisted_53 = { class: "box-desc" }
const _hoisted_54 = { class: "mt-18" }
const _hoisted_55 = { class: "mr-12 flex-center" }
const _hoisted_56 = { style: {"text-transform":"uppercase"} }
const _hoisted_57 = { class: "body-width-reponsive-max pb-32 pdf" }
const _hoisted_58 = { class: "body-width-reponsive-mobile" }
const _hoisted_59 = {
  class: "box-card detail",
  style: {}
}
const _hoisted_60 = { class: "logo-container" }
const _hoisted_61 = ["src"]
const _hoisted_62 = {
  key: 1,
  src: _imports_6,
  alt: "MyCulture logo",
  class: "logo",
  style: {"display":"block","margin":"auto"}
}
const _hoisted_63 = { class: "analysing-results-section" }
const _hoisted_64 = { class: "result-header" }
const _hoisted_65 = { class: "result-details" }
const _hoisted_66 = { ref: "audit_section" }
const _hoisted_67 = { ref: "skills_section" }
const _hoisted_68 = { ref: "questions_section" }
const _hoisted_69 = { ref: "microlearning_section" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_affix = _resolveComponent("el-affix")!
  const _component_el_backtop = _resolveComponent("el-backtop")!
  const _component_Audit = _resolveComponent("Audit")!
  const _component_Skills = _resolveComponent("Skills")!
  const _component_Questions = _resolveComponent("Questions")!
  const _component_Microlearning = _resolveComponent("Microlearning")!
  const _component_vue3_html2pdf = _resolveComponent("vue3-html2pdf")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t("coreValuesAnalyzer.My_Core_Values")), 1),
        _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$t("coreValuesAnalyzer.Analyze_everything_you")), 1),
        _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.$t("coreValuesAnalyzer.Enter_from_1_to")), 1),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.coreValue, (input, index) => {
              return (_openBlock(), _createElementBlock("div", {
                key: 'for_core_value_' + index,
                class: "input-containter"
              }, [
                _createElementVNode("div", _hoisted_8, _toDisplayString(index + 1) + ".", 1),
                _withDirectives(_createElementVNode("input", {
                  "onUpdate:modelValue": ($event: any) => ((_ctx.coreValue[index].value) = $event),
                  onKeyup: _withKeys(($event: any) => (_ctx.addInputOnEnter(index + 1)), ["enter"]),
                  ref_for: true,
                  ref: 'input_core_value_' + index,
                  id: 'input_core_value_' + index,
                  placeholder: "...",
                  style: {"width":"90%"},
                  type: "text"
                }, null, 40, _hoisted_9), [
                  [_vModelText, _ctx.coreValue[index].value]
                ])
              ]))
            }), 128))
          ]),
          (_ctx.analyzerLoading)
            ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                _createTextVNode(_toDisplayString(_ctx.$t("coreValuesAnalyzer.Analyzing")) + " ", 1),
                _cache[7] || (_cache[7] = _createElementVNode("svg", {
                  version: "1.1",
                  id: "L9",
                  xmlns: "http://www.w3.org/2000/svg",
                  "xmlns:xlink": "http://www.w3.org/1999/xlink",
                  x: "0px",
                  y: "0px",
                  viewBox: "0 0 100 100",
                  "enable-background": "new 0 0 0 0",
                  "xml:space": "preserve"
                }, [
                  _createElementVNode("path", {
                    fill: "#fff",
                    d: "M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50"
                  }, [
                    _createElementVNode("animateTransform", {
                      attributeName: "transform",
                      attributeType: "XML",
                      type: "rotate",
                      dur: "1s",
                      from: "0 50 50",
                      to: "360 50 50",
                      repeatCount: "indefinite"
                    })
                  ])
                ], -1))
              ]))
            : (_openBlock(), _createElementBlock("div", {
                key: 1,
                onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onClickAnalyzeButton && _ctx.onClickAnalyzeButton(...args))),
                class: "btn02 btn-50 btn-primary flex-mid max-m-mt-24 anylze-btn"
              }, _toDisplayString(_ctx.$t("coreValuesAnalyzer.Analyze")), 1))
        ]),
        _createElementVNode("div", _hoisted_11, [
          _cache[8] || (_cache[8] = _createElementVNode("div", { class: "tags-label" }, "Inspiration:", -1)),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.coreValueTags, (value) => {
            return (_openBlock(), _createElementBlock("div", {
              onClick: ($event: any) => (_ctx.addTagToCoreValue(value.label)),
              key: value.id,
              class: _normalizeClass(["color-value-tag", _ctx.matchingCoreValue(value.label)])
            }, _toDisplayString(value.label), 11, _hoisted_12))
          }), 128))
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_13, [
      (!_ctx.analyzerShowing)
        ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
            _createElementVNode("div", _hoisted_15, [
              _createElementVNode("div", _hoisted_16, [
                _createElementVNode("div", _hoisted_17, [
                  _createElementVNode("div", _hoisted_18, _toDisplayString(_ctx.$t("coreValuesAnalyzer.Audit_your_core_values")), 1),
                  _createElementVNode("div", _hoisted_19, [
                    _createElementVNode("div", null, _toDisplayString(_ctx.$t("coreValuesAnalyzer.Analyze_how_well_your")), 1),
                    _cache[9] || (_cache[9] = _createElementVNode("div", null, " ", -1)),
                    _createElementVNode("div", null, _toDisplayString(_ctx.$t("coreValuesAnalyzer.Ratings_recommendations_and")), 1)
                  ])
                ]),
                _cache[12] || (_cache[12] = _createElementVNode("div", { class: "grid-item" }, [
                  _createElementVNode("img", {
                    class: "image-box",
                    src: _imports_0,
                    alt: "Audit your core values"
                  })
                ], -1)),
                _cache[13] || (_cache[13] = _createElementVNode("div", { class: "grid-item" }, [
                  _createElementVNode("img", {
                    class: "image-box",
                    src: _imports_1,
                    alt: "Get a Skills Development Plan"
                  })
                ], -1)),
                _createElementVNode("div", _hoisted_20, [
                  _createElementVNode("div", _hoisted_21, _toDisplayString(_ctx.$t("coreValuesAnalyzer.Get_a_Skills_Development")), 1),
                  _createElementVNode("div", _hoisted_22, [
                    _createElementVNode("div", null, _toDisplayString(_ctx.$t("coreValuesAnalyzer.Get_a_skill_evelopment")), 1),
                    _cache[10] || (_cache[10] = _createElementVNode("div", null, " ", -1)),
                    _createElementVNode("div", null, _toDisplayString(_ctx.$t("coreValuesAnalyzer.Leaders_an_encourage_and")), 1)
                  ])
                ]),
                _createElementVNode("div", _hoisted_23, [
                  _createElementVNode("div", _hoisted_24, _toDisplayString(_ctx.$t("coreValuesAnalyzer.Generate_Survey_Questions")), 1),
                  _createElementVNode("div", _hoisted_25, [
                    _createElementVNode("div", null, _toDisplayString(_ctx.$t("coreValuesAnalyzer.Help_your_employees_regularly")), 1)
                  ])
                ]),
                _cache[14] || (_cache[14] = _createElementVNode("div", { class: "grid-item" }, [
                  _createElementVNode("img", {
                    class: "image-box",
                    src: _imports_2,
                    alt: "Generate Survey Questions"
                  })
                ], -1)),
                _cache[15] || (_cache[15] = _createElementVNode("div", { class: "grid-item" }, [
                  _createElementVNode("img", {
                    class: "image-box",
                    src: _imports_3,
                    alt: "Learning Content to Support Your Values"
                  })
                ], -1)),
                _createElementVNode("div", _hoisted_26, [
                  _createElementVNode("div", _hoisted_27, _toDisplayString(_ctx.$t("coreValuesAnalyzer.Learning_Content_to_Support")), 1),
                  _createElementVNode("div", _hoisted_28, [
                    _createElementVNode("div", null, _toDisplayString(_ctx.$t("coreValuesAnalyzer.Get_micro_learning_content")), 1),
                    _cache[11] || (_cache[11] = _createElementVNode("div", null, " ", -1)),
                    _createElementVNode("div", null, _toDisplayString(_ctx.$t("coreValuesAnalyzer.Short_and_easily_digestible")), 1)
                  ])
                ])
              ])
            ])
          ]))
        : _createCommentVNode("", true),
      (_ctx.analyzerShowing)
        ? (_openBlock(), _createElementBlock("div", _hoisted_29, [
            _createVNode(_component_el_affix, { offset: 0 }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_30, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tabs, (tab, index) => {
                    return (_openBlock(), _createElementBlock("div", {
                      key: 'tabs_' + index,
                      class: _normalizeClass(["analysis-tab", `${tab.activeTab ? 'active' : ''}`]),
                      onClick: ($event: any) => (_ctx.clickToTab(tab))
                    }, [
                      (index !== 0 && _ctx.lockedForLogin)
                        ? (_openBlock(), _createElementBlock("img", _hoisted_32))
                        : _createCommentVNode("", true),
                      _createTextVNode(" " + _toDisplayString(_ctx.$t("coreValuesAnalyzer." + tab.label)), 1)
                    ], 10, _hoisted_31))
                  }), 128))
                ])
              ]),
              _: 1
            }),
            _createElementVNode("div", _hoisted_33, [
              _createVNode(_component_el_backtop, { bottom: 54 }, {
                default: _withCtx(() => [
                  _createElementVNode("div", {
                    class: "back-to-top flex-mid",
                    style: _normalizeStyle(`color: ${_ctx.companyColor}`)
                  }, _cache[16] || (_cache[16] = [
                    _createElementVNode("span", null, "▲", -1)
                  ]), 4)
                ]),
                _: 1
              })
            ])
          ]))
        : _createCommentVNode("", true),
      (_ctx.analyzerShowing)
        ? (_openBlock(), _createElementBlock("div", _hoisted_34, [
            _createElementVNode("div", _hoisted_35, [
              _createVNode(_component_Audit, { locked: _ctx.lockedForLogin }, null, 8, ["locked"])
            ], 512),
            (_ctx.lockedForLogin)
              ? (_openBlock(), _createElementBlock("div", _hoisted_36, [
                  _createElementVNode("div", _hoisted_37, [
                    _createElementVNode("img", {
                      src: _ctx.imagePathAnalysisSection('lock_open_white_80dp@2x'),
                      alt: "lock_open_white_80dp",
                      class: "image-lock max-m-none"
                    }, null, 8, _hoisted_38)
                  ]),
                  _createElementVNode("div", _hoisted_39, [
                    _createElementVNode("div", _hoisted_40, [
                      _createElementVNode("img", {
                        src: _ctx.imagePathAnalysisSection('lock_open_white_80dp@2x'),
                        alt: "lock_open_white_80dp",
                        class: "image-lock min-m-none"
                      }, null, 8, _hoisted_41),
                      _createElementVNode("div", _hoisted_42, _toDisplayString(_ctx.$t("coreValuesAnalyzer.Create_an_account_to")), 1)
                    ]),
                    _createElementVNode("div", _hoisted_43, [
                      _createElementVNode("div", {
                        class: "btn btn-48 btn-FFFFFF btn-width",
                        onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.gotoSignupPage && _ctx.gotoSignupPage(...args)))
                      }, [
                        _createElementVNode("span", _hoisted_44, _toDisplayString(_ctx.$t("createAccount.Sign_up")), 1)
                      ]),
                      _createElementVNode("div", {
                        onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.gotoLoginPage && _ctx.gotoLoginPage(...args))),
                        class: "btn btn-48 btn-solid-FFFFFF btn-width ml-24"
                      }, [
                        _createElementVNode("span", _hoisted_45, _toDisplayString(_ctx.$t("createAccount.Log_in")), 1)
                      ])
                    ])
                  ])
                ]))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_46, [
              _createVNode(_component_Skills, { locked: _ctx.lockedForLogin }, null, 8, ["locked"])
            ], 512),
            _createElementVNode("div", _hoisted_47, [
              _createVNode(_component_Questions, { locked: _ctx.lockedForLogin }, null, 8, ["locked"])
            ], 512),
            _createElementVNode("div", _hoisted_48, [
              _createVNode(_component_Microlearning, { locked: _ctx.lockedForLogin }, null, 8, ["locked"])
            ], 512),
            _createElementVNode("div", _hoisted_49, [
              _createElementVNode("div", _hoisted_50, [
                _createElementVNode("div", _hoisted_51, [
                  _createElementVNode("div", _hoisted_52, _toDisplayString(_ctx.$t("coreValuesAnalyzer.Download_This_Report")), 1),
                  _createElementVNode("div", _hoisted_53, _toDisplayString(_ctx.$t("coreValuesAnalyzer.Download_to_save")), 1)
                ]),
                _createElementVNode("div", _hoisted_54, [
                  _createElementVNode("div", {
                    onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.downloadPdf && _ctx.downloadPdf(...args))),
                    class: "btn btn-50 btn-primary flex-mid max-m-mt-24 anylze-btn",
                    style: {"margin":"auto"}
                  }, [
                    _createElementVNode("div", _hoisted_55, [
                      _createElementVNode("img", {
                        src: _imports_5,
                        class: "icon-20",
                        style: _normalizeStyle(`${!_ctx.isLightColor ? 'filter: invert(1)' : ''}`),
                        alt: "download_24px"
                      }, null, 4)
                    ]),
                    _createElementVNode("span", _hoisted_56, _toDisplayString(_ctx.$t("coreValuesAnalyzer.Download")), 1)
                  ])
                ])
              ])
            ])
          ]))
        : _createCommentVNode("", true),
      (_ctx.analyzerShowing)
        ? (_openBlock(), _createBlock(_component_vue3_html2pdf, {
            key: 3,
            "show-layout": false,
            "float-layout": true,
            "enable-download": true,
            "preview-modal": true,
            "paginate-elements-by-height": 50,
            filename: "MyCoreValues",
            "pdf-quality": 1.2,
            "manual-pagination": true,
            "pdf-format": "legal",
            "pdf-orientation": "portrait",
            "pdf-content-width": "1440px",
            onXProgress: _cache[4] || (_cache[4] = ($event: any) => (_ctx.onProgress($event))),
            onXHasStartedGeneration: _cache[5] || (_cache[5] = ($event: any) => (_ctx.hasStartedGeneration())),
            onXHasGenerated: _cache[6] || (_cache[6] = ($event: any) => (_ctx.hasGenerated($event))),
            onBeforeDownload: _ctx.beforeDownload,
            ref: "html2Pdf"
          }, {
            "pdf-content": _withCtx(() => [
              _createElementVNode("div", _hoisted_57, [
                _createElementVNode("div", _hoisted_58, [
                  _createElementVNode("div", _hoisted_59, [
                    _createElementVNode("div", _hoisted_60, [
                      (_ctx.companyLogoUrl)
                        ? (_openBlock(), _createElementBlock("img", {
                            key: 0,
                            src: _ctx.companyLogoUrl,
                            alt: "MyCulture logo",
                            class: "logo",
                            style: {"display":"block","margin":"auto"}
                          }, null, 8, _hoisted_61))
                        : (_openBlock(), _createElementBlock("img", _hoisted_62))
                    ]),
                    _createElementVNode("div", _hoisted_63, [
                      _createElementVNode("div", _hoisted_64, _toDisplayString(_ctx.$t("coreValuesAnalyzer.Download_pdf_title")), 1),
                      _createElementVNode("ol", _hoisted_65, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.coreValue, (input, index) => {
                          return (_openBlock(), _createElementBlock("li", {
                            key: 'for_core_value_' + index,
                            class: "input-containter"
                          }, _toDisplayString(index + 1 + ". " + input.value), 1))
                        }), 128))
                      ]),
                      _createElementVNode("div", _hoisted_66, [
                        _createVNode(_component_Audit, { locked: _ctx.lockedForLogin }, null, 8, ["locked"])
                      ], 512),
                      _createElementVNode("div", _hoisted_67, [
                        _createVNode(_component_Skills, { locked: _ctx.lockedForLogin }, null, 8, ["locked"])
                      ], 512),
                      _createElementVNode("div", _hoisted_68, [
                        _createVNode(_component_Questions, { locked: _ctx.lockedForLogin }, null, 8, ["locked"])
                      ], 512),
                      _createElementVNode("div", _hoisted_69, [
                        _createVNode(_component_Microlearning, { locked: _ctx.lockedForLogin }, null, 8, ["locked"])
                      ], 512)
                    ])
                  ])
                ])
              ])
            ]),
            _: 1
          }, 8, ["onBeforeDownload"]))
        : _createCommentVNode("", true)
    ])
  ], 64))
}