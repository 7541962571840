import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "core-value-conten"
}
const _hoisted_2 = { class: "" }
const _hoisted_3 = { class: "border-box" }
const _hoisted_4 = { class: "box-title" }
const _hoisted_5 = { class: "box-desc" }
const _hoisted_6 = { class: "guidance-card" }
const _hoisted_7 = { class: "card-title" }
const _hoisted_8 = { class: "card-des" }
const _hoisted_9 = {
  key: 1,
  class: "core-value-conten"
}
const _hoisted_10 = { class: "" }
const _hoisted_11 = { class: "border-box" }
const _hoisted_12 = { class: "box-title" }
const _hoisted_13 = { class: "box-desc" }
const _hoisted_14 = { class: "icon" }
const _hoisted_15 = {
  key: 0,
  xmlns: "http://www.w3.org/2000/svg",
  width: "24",
  height: "24",
  viewBox: "0 0 24 24",
  fill: "none"
}
const _hoisted_16 = {
  key: 1,
  xmlns: "http://www.w3.org/2000/svg",
  width: "20",
  height: "20",
  viewBox: "0 0 20 20",
  fill: "none"
}
const _hoisted_17 = {
  key: 2,
  xmlns: "http://www.w3.org/2000/svg",
  width: "16",
  height: "16",
  viewBox: "0 0 16 16",
  fill: "none"
}
const _hoisted_18 = {
  key: 3,
  xmlns: "http://www.w3.org/2000/svg",
  width: "24",
  height: "24",
  viewBox: "0 0 24 24",
  fill: "none"
}
const _hoisted_19 = {
  key: 4,
  xmlns: "http://www.w3.org/2000/svg",
  width: "24",
  height: "24",
  viewBox: "0 0 24 24",
  fill: "none"
}
const _hoisted_20 = { class: "card-title" }
const _hoisted_21 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_masonry_wall = _resolveComponent("masonry-wall")!

  return (!_ctx.microlearningLoaded || _ctx.locked)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$t("coreValuesAnalyzer.Microlearning")), 1),
            _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.$t("coreValuesAnalyzer.These_micro_learning_content")), 1),
            _createVNode(_component_masonry_wall, {
              items: ['1', '2'],
              "ssr-columns": 2,
              "column-width": 350,
              gap: 30
            }, {
              default: _withCtx(({ item, index }) => [
                _createElementVNode("div", {
                  class: _normalizeClass(["guidance-box", item + index])
                }, [
                  _createElementVNode("div", _hoisted_6, [
                    _createElementVNode("div", _hoisted_7, [
                      _createElementVNode("div", {
                        class: _normalizeClass([`${_ctx.locked ? 'image-grey' : 'image-skeleton'}`, "opa-03 br-4"]),
                        style: {"width":"40%"}
                      }, "   ", 2)
                    ]),
                    _createElementVNode("div", _hoisted_8, [
                      (_openBlock(), _createElementBlock(_Fragment, null, _renderList(5, (i2) => {
                        return _createElementVNode("div", {
                          key: i2 + '_mock_text',
                          class: _normalizeClass([`${_ctx.locked ? 'image-grey' : 'image-skeleton'}`, "opa-03 mt-2 br-4"]),
                          style: {"width":"100%"}
                        }, "   ", 2)
                      }), 64)),
                      _createElementVNode("div", {
                        class: _normalizeClass([`${_ctx.locked ? 'image-grey' : 'image-skeleton'}`, "opa-03 mt-2 br-4"]),
                        style: {"width":"80%"}
                      }, "   ", 2)
                    ])
                  ])
                ], 2)
              ]),
              _: 1
            })
          ])
        ])
      ]))
    : (_openBlock(), _createElementBlock("div", _hoisted_9, [
        _createElementVNode("div", _hoisted_10, [
          _createElementVNode("div", _hoisted_11, [
            _createElementVNode("div", _hoisted_12, _toDisplayString(_ctx.$t("coreValuesAnalyzer.Microlearning")), 1),
            _createElementVNode("div", _hoisted_13, _toDisplayString(_ctx.$t("coreValuesAnalyzer.These_micro_learning_content")), 1),
            _createVNode(_component_masonry_wall, {
              items: _ctx.microlearningData.microlearning,
              "ssr-columns": 1,
              "column-width": 350,
              gap: 30
            }, {
              default: _withCtx(({ item, index }) => [
                _createElementVNode("div", {
                  class: _normalizeClass(["guidance-card", index])
                }, [
                  _createElementVNode("div", _hoisted_14, [
                    (item.mricoLabel === 'Quote')
                      ? (_openBlock(), _createElementBlock("svg", _hoisted_15, _cache[0] || (_cache[0] = [
                          _createElementVNode("path", {
                            d: "M3.81951 14.4342C2.96097 13.5228 2.5 12.4999 2.5 10.8419C2.5 7.92569 4.54709 5.31135 7.52549 4.01929L8.2694 5.1673C5.49003 6.67113 4.94682 8.62167 4.7297 9.85167C5.17719 9.62025 5.76298 9.53884 6.33726 9.59209C7.8409 9.73142 9.026 10.9658 9.026 12.4999C9.026 14.1108 7.72013 15.4166 6.1093 15.4166C5.21508 15.4166 4.35997 15.0079 3.81951 14.4342ZM12.1528 14.4342C11.2943 13.5228 10.8333 12.4999 10.8333 10.8419C10.8333 7.92569 12.8804 5.31135 15.8588 4.01929L16.6027 5.1673C13.8233 6.67113 13.2802 8.62167 13.063 9.85167C13.5105 9.62025 14.0963 9.53884 14.6706 9.59209C16.1742 9.73142 17.3593 10.9658 17.3593 12.4999C17.3593 14.1108 16.0535 15.4166 14.4427 15.4166C13.5484 15.4166 12.6933 15.0079 12.1528 14.4342Z",
                            fill: "black"
                          }, null, -1)
                        ])))
                      : (item.mricoLabel === 'Challenge')
                        ? (_openBlock(), _createElementBlock("svg", _hoisted_16, _cache[1] || (_cache[1] = [
                            _createElementVNode("path", {
                              d: "M17.6153 2.5C17.8455 2.5 18.032 2.68655 18.032 2.91667C18.032 2.98917 18.013 3.06043 17.977 3.12339L15 8.33333L17.977 13.5432C18.0912 13.7431 18.0218 13.9976 17.822 14.1117C17.759 14.1477 17.6878 14.1667 17.6153 14.1667H3.33329V18.3333H1.66663V2.5H17.6153ZM15.4613 4.16667H3.33329V12.5H15.4613L13.0804 8.33333L15.4613 4.16667Z",
                              fill: "black"
                            }, null, -1)
                          ])))
                        : (item.mricoLabel === 'Idea')
                          ? (_openBlock(), _createElementBlock("svg", _hoisted_17, _cache[2] || (_cache[2] = [
                              _createElementVNode("path", {
                                d: "M6.64868 11.9999H7.33329V8.66659H8.66663V11.9999H9.35123C9.43923 11.1989 9.84803 10.5376 10.5117 9.81499C10.5864 9.73365 11.0658 9.23752 11.1227 9.16665C11.6878 8.46199 12 7.58992 12 6.66659C12 4.45745 10.2091 2.66659 7.99996 2.66659C5.79082 2.66659 3.99996 4.45745 3.99996 6.66659C3.99996 7.58945 4.31179 8.46105 4.87637 9.16552C4.93332 9.23659 5.41383 9.73405 5.48765 9.81445C6.15173 10.5375 6.56065 11.1989 6.64868 11.9999ZM6.66663 13.3333V13.9999H9.33329V13.3333H6.66663ZM3.83593 9.99939C3.10426 9.08639 2.66663 7.92759 2.66663 6.66659C2.66663 3.72107 5.05444 1.33325 7.99996 1.33325C10.9455 1.33325 13.3333 3.72107 13.3333 6.66659C13.3333 7.92825 12.8952 9.08759 12.1628 10.0009C11.7493 10.5165 10.6666 11.3333 10.6666 12.3333V13.9999C10.6666 14.7363 10.0697 15.3333 9.33329 15.3333H6.66663C5.93025 15.3333 5.33329 14.7363 5.33329 13.9999V12.3333C5.33329 11.3333 4.24968 10.5157 3.83593 9.99939Z",
                                fill: "black"
                              }, null, -1)
                            ])))
                          : (item.mricoLabel === 'Passage')
                            ? (_openBlock(), _createElementBlock("svg", _hoisted_18, _cache[3] || (_cache[3] = [
                                _createElementVNode("path", {
                                  d: "M5 17H9C10.6569 17 12 18.3431 12 20V10C12 7.17157 12 5.75736 11.1213 4.87868C10.2426 4 8.82843 4 6 4H5C4.05719 4 3.58579 4 3.29289 4.29289C3 4.58579 3 5.05719 3 6V15C3 15.9428 3 16.4142 3.29289 16.7071C3.58579 17 4.05719 17 5 17Z",
                                  stroke: "#33363F",
                                  "stroke-width": "2"
                                }, null, -1),
                                _createElementVNode("path", {
                                  d: "M19 17H15C13.3431 17 12 18.3431 12 20V10C12 7.17157 12 5.75736 12.8787 4.87868C13.7574 4 15.1716 4 18 4H19C19.9428 4 20.4142 4 20.7071 4.29289C21 4.58579 21 5.05719 21 6V15C21 15.9428 21 16.4142 20.7071 16.7071C20.4142 17 19.9428 17 19 17Z",
                                  stroke: "#33363F",
                                  "stroke-width": "2"
                                }, null, -1)
                              ])))
                            : (item.mricoLabel === 'Multiple-choice')
                              ? (_openBlock(), _createElementBlock("svg", _hoisted_19, _cache[4] || (_cache[4] = [
                                  _createElementVNode("circle", {
                                    cx: "12",
                                    cy: "12",
                                    r: "9",
                                    stroke: "#33363F",
                                    "stroke-width": "2"
                                  }, null, -1),
                                  _createElementVNode("circle", {
                                    cx: "12",
                                    cy: "18",
                                    r: "0.5",
                                    fill: "#33363F",
                                    stroke: "#33363F"
                                  }, null, -1),
                                  _createElementVNode("path", {
                                    d: "M12 16V14.5811C12 13.6369 12.6042 12.7986 13.5 12.5V12.5C14.3958 12.2014 15 11.3631 15 10.4189V9.90569C15 8.30092 13.6991 7 12.0943 7H12C10.3431 7 9 8.34315 9 10V10",
                                    stroke: "#33363F",
                                    "stroke-width": "2"
                                  }, null, -1)
                                ])))
                              : _createCommentVNode("", true)
                  ]),
                  _createElementVNode("div", _hoisted_20, _toDisplayString(item.mricoLabel), 1),
                  _createElementVNode("div", {
                    class: "card-des",
                    innerHTML: _ctx.nl2br(item.mricoDescription)
                  }, null, 8, _hoisted_21)
                ], 2)
              ]),
              _: 1
            }, 8, ["items"])
          ])
        ])
      ]))
}